import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../user/user.service';
import { Observable, map } from 'rxjs';

export const AuthGuard = (): Observable<boolean | Promise<boolean>> => {
  const user = inject(UserService);
  const router = inject(Router);
  return user.isLogged.pipe(
    map(async (isLogged) => {
      if (isLogged) {
        return true;
      }
      return await router.navigate(['']);
    })
  );
};
